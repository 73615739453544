import { axiosCreate } from './Api'
import store from '../state/store'
import dayjs from 'dayjs'

export async function getTimePeriods({
  startDate = '2020-01-01',
  endDate = '2020-01-31',
  warehouseID = 'Asse'
} = {}) {
  const result = await axiosCreate.get(`/custvwbusinesshour`, {
    params: {
      'DateBusinessDay >': dayjs(startDate)
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      'DateBusinessDay <': dayjs(endDate)
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      WarehouseID: warehouseID
    },
    headers: { authorization: store.getters.token }
  })

  return result.data
}

export async function getTimePeriodsExact({
  startDate = '',
  endDate = '',
  warehouseID
} = {}) {
  const result = await axiosCreate.get(`/custvwbusinesshour`, {
    params: {
      'DateBusinessDay >': startDate,
      'DateBusinessDay <': endDate,
      WarehouseID: warehouseID ?? ''
    },
    headers: { authorization: store.getters.token }
  })

  return result.data
}
